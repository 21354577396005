import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import apiClient from "./api"; // Changed from axios

// Import global styles
import "./styles/base.css";
import "@fortawesome/fontawesome-free/css/all.css";

// Syncfusion license registration
import { registerLicense } from "@syncfusion/ej2-base";
registerLicense(process.env.VUE_APP_SYNCFUSION_LICENSE);

// CSRF token handling
let isTokenBeingFetched = false;

apiClient.interceptors.request.use(async (config) => {
  if (config.method?.toLowerCase() === "get") return config;

  if (!store.state.auth.csrfToken && !isTokenBeingFetched) {
    // Updated path
    isTokenBeingFetched = true;
    store.dispatch("auth/fetchCsrfToken").finally(() => {
      // Namespaced dispatch
      isTokenBeingFetched = false;
    });
  }

  // Wait for token if it's being fetched
  if (isTokenBeingFetched) {
    await new Promise((resolve) => {
      const checkInterval = setInterval(() => {
        if (!isTokenBeingFetched) {
          clearInterval(checkInterval);
          resolve();
        }
      }, 50);
    });
  }

  config.headers["X-CSRF-Token"] = store.state.auth.csrfToken; // Updated path
  return config;
});

// Set up router navigation guards
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Biglo";
  next();
});

// Create and mount Vue application
const app = createApp(App);
app.use(router);
app.use(store);

// Fetch CSRF token before mounting the app
store.dispatch("auth/fetchCsrfToken").finally(() => {
  // Namespaced dispatch
  app.mount("#app");
});
