<template>
  <div
    class="advertisement-item border border-brand-yellow rounded-xl shadow-md"
  >
    <img v-bind:src="resultsItem.imageUrl" class="w-[33.33%] rounded-s-lg" />
    <div class="text-container flex flex-col justify-around mx-auto">
      <h3 class="advertisement-name text-xl text-brand-darkblue font-semibold">
        {{ resultsItem.name }}
      </h3>
      <div class="flex flex-col justify-between">
        <div class="flex flex-row justify-center">
          <label for="advertisement-price" class="mr-4">Price:</label>
          <label for="advertisement-space" class="ml-4">Space Available:</label>
        </div>
        <div class="flex flex-row justify-center">
          <p id="advertisement-price" class="mr-8">${{ resultsItem.price }}</p>
          <p id="advertisement-space" class="ml-8">
            {{ resultsItem.space }} m²
          </p>
        </div>
      </div>
      <router-link v-bind:to="'/spaces/' + resultsItem.id">
        <button
          class="text-brand-darkblue border border-brand-darkblue rounded-md max-w-fit ml-auto px-4 py-2 bg-brand-yellow font-medium"
        >
          View Details
        </button>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "ResultsItem",
  props: ["resultsItem"],
};
</script>
<style scoped>
.advertisement-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 2%;
  position: relative;
  width: 100%;
  height: 32%;
}
.text-container {
  height: 188px;
  width: fit-content;
}
button {
  width: 100%;
}
input[type="radio"]:checked + label {
  background-color: var(--clr-brand-darkblue);
  color: var(--clr-brand-yellow);
  border: 1px solid var(--clr-brand-yellow);
}
</style>
