<template>
  <div class="home">
    <TransparentHeader />
    <OurMission />
    <CommonFooter />
  </div>
</template>

<script>
import TransparentHeader from "@/components/common/TransparentHeader.vue";
import OurMission from "@/components/content/OurMission.vue";
import CommonFooter from "@/components/common/Footer.vue";

export default {
  name: "HomePage",
  components: {
    TransparentHeader,
    OurMission,
    CommonFooter,
  },
};
</script>

<style></style>
