<template>
  <div class="relative mx-auto description-step">
    <span class="block text-2xl font-semibold text-brand-darkblue text-center">
      <i class="fa-solid fa-text"></i>
      12th Step: Description
    </span>

    <div
      class="input-container mx-auto mt-4 flex flex-col relative min-sm:max-w-xl sm:max-w-full"
    >
      <textarea
        name="description"
        id="description"
        class="w-full py-3 px-4 rounded-lg resize-none md:h-48 min-md:h-56 min-lg:h-72"
        autocapitalize="on"
        autocorrect="on"
        autocomplete="off"
        v-model="localDescription"
        placeholder="Describe the services your company offers."
        minlength="50"
        required
      ></textarea>

      <button
        :disabled="!canProceed"
        class="relative mx-auto rounded-lg mt-5 px-4 py-3 bg-brand-darkblue text-brand-yellow font-medium border border-brand-darkblue hover:bg-brand-yellow hover:text-brand-darkblue disabled:bg-grey-light disabled:text-brand-darkblue disabled:cursor-not-allowed disabled:hover:bg-grey-light disabled:hover:text-brand-darkblue"
        @click="submitService"
      >
        Submit Service
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DescriptionStep",
  props: {
    description: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localDescription: this.description, // Initialize with prop value
    };
  },
  watch: {
    // Watch for changes in the local description and emit updates to the parent
    localDescription(newValue) {
      this.$emit("update:description", newValue);
    },
  },
  computed: {
    canProceed() {
      return this.localDescription.length >= 50;
    },
  },
  methods: {
    submitService() {
      this.$emit("submit-service");
    },
  },
  mounted() {
    document.getElementById("description").focus();
  },
};
</script>

<style scoped>
textarea {
  border: 1px solid;
  border-color: #696969;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}
textarea:focus:placeholder-shown {
  border-color: #0080ff !important;
  box-shadow:
    0 4px 6px -1px rgb(0 128 255 / 0.25),
    0 2px 4px -2px rgb(0 128 255 / 0.25) !important;
}
textarea:focus:invalid {
  border-color: #f7b500;
  box-shadow:
    0 4px 6px -1px rgb(247 181 0 / 0.25),
    0 2px 4px -2px rgb(247 181 0 / 0.25);
}
textarea:not(:focus):user-invalid {
  border-color: #e60000;
}
textarea:user-valid {
  border-color: #00d112 !important;
}
textarea:focus:user-valid {
  border-color: #00d112 !important;
  box-shadow:
    0 4px 6px -1px rgb(0 209 18 / 0.25),
    0 2px 4px -2px rgb(0 209 18 / 0.25) !important;
}
textarea:valid {
  border-color: #00d112 !important;
}
textarea:focus:valid {
  border-color: #00d112 !important;
  box-shadow:
    0 4px 6px -1px rgb(0 209 18 / 0.25),
    0 2px 4px -2px rgb(0 209 18 / 0.25) !important;
}
</style>
