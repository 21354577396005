<template>
  <div class="relative mx-auto location-step p-4">
    <!-- Step Title -->
    <span class="block text-2xl font-semibold text-brand-darkblue text-center">
      <i class="fa-solid fa-map-location-dot mr-2"></i>
      2nd Step: Location
    </span>

    <!-- Country Input -->
    <CountryInputButton
      v-if="currentInput === 'country'"
      v-model="location.country"
      @country-selected="onCountrySelected"
      ref="countryInput"
      class="max-w-md sm:max-w-full mt-4"
      required
    />

    <!-- Dynamic Inputs for Region, City, ZIP, Address -->
    <div v-else v-for="(input, index) in locationInputs" :key="input.name">
      <div
        class="input-container mx-auto mt-4 flex flex-col relative max-w-md sm:max-w-full"
        v-if="currentInputIndex === index"
      >
        <label
          :for="input.name"
          class="text-brand-darkblue font-medium text-lg mb-4"
        >
          <i :class="input.icon" class="mr-1"></i>
          {{ input.name.charAt(0).toUpperCase() + input.name.slice(1) }}
        </label>
        <div class="rounded-l-lg flex items-center">
          <input
            :ref="`locationInput${index}`"
            class="w-full py-3 px-4 rounded-l-lg"
            v-model="location[input.name]"
            :id="input.name"
            :placeholder="`Enter your ${input.name}`"
            required
            :pattern="input.pattern"
          />
          <button
            :disabled="!isValidInput(location[input.name], input.pattern)"
            v-if="currentInputIndex < locationInputs.length - 1"
            @click="nextInput"
            class="py-3 px-4 bg-brand-darkblue text-brand-yellow border border-brand-darkblue rounded-r-lg hover:bg-brand-yellow hover:text-brand-darkblue disabled:bg-grey-light disabled:text-brand-darkblue disabled:cursor-not-allowed hover:disabled:bg-grey-light hover:disabled:text-brand-darkblue"
          >
            <i class="fa-solid fa-arrow-right"></i>
          </button>
          <!-- Button for Address Input -->
          <button
            :disabled="!isValidInput(location[input.name], input.pattern)"
            v-if="currentInputIndex === locationInputs.length - 1"
            class="py-3 px-4 bg-brand-darkblue text-brand-yellow border border-brand-darkblue rounded-r-lg hover:bg-brand-yellow hover:text-brand-darkblue disabled:bg-grey-light disabled:text-brand-darkblue disabled:cursor-not-allowed hover:disabled:bg-grey-light hover:disabled:text-brand-darkblue"
            @click="showAdditionalOptions"
          >
            <i class="fa-solid fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- Checkbox for Has Other Locations -->
    <div v-if="currentInput === 'hasOtherLocations'" class="mt-4">
      <SwitchCheckbox
        class="mb-4"
        v-model="location.hasOtherLocations"
        label="Do you have other locations?"
        id="hasOtherLocations"
      />

      <!-- Other Locations Component -->
      <OtherLocations
        v-if="
          currentInput === 'hasOtherLocations' && location.hasOtherLocations
        "
        :otherLocations="location.otherLocations"
        @update:otherLocations="location.otherLocations = $event"
      />

      <button
        type="button"
        class="block mx-auto px-4 py-3 bg-brand-darkblue text-brand-yellow border border-brand-darkblue hover:bg-brand-yellow hover:text-brand-darkblue rounded-lg"
        @click="nextInput"
      >
        Next
      </button>
    </div>

    <!-- Checkbox for Has International Coverage -->
    <div v-if="currentInput === 'hasInternationalCoverage'" class="mt-4">
      <SwitchCheckbox
        v-model="location.hasInternationalCoverage"
        label="Does your comapny offer International Coverage?"
        id="hasInternationalCoverage"
      />
      <SwitchesCheckboxes
        v-if="location.hasInternationalCoverage"
        class="relative mx-auto mt-4"
        :options="internationalCoverageOptions"
        v-model="location.internationalCoverage"
      />
    </div>

    <!-- Next Step Button -->
    <div
      class="button-container relative mx-auto w-fit"
      v-if="currentInput === 'hasInternationalCoverage'"
    >
      <button
        type="button"
        :disabled="!isStepComplete"
        class="next-step relative mx-auto rounded-lg mt-5 px-4 py-3 bg-brand-darkblue text-brand-yellow font-medium border border-brand-darkblue hover:bg-brand-yellow hover:text-brand-darkblue disabled:bg-grey-light disabled:text-brand-darkblue disabled:cursor-not-allowed hover:disabled:bg-grey-light hover:disabled:text-brand-darkblue"
        @click="nextStep"
      >
        Next Step
      </button>
    </div>
  </div>
</template>

<script>
import CountryInputButton from "@/components/common/Forms/CountryInputButton.vue";
import SwitchesCheckboxes from "@/components/common/Forms/SwitchesCheckboxes.vue";
import SwitchCheckbox from "@/components/common/Forms/SwitchCheckbox.vue";
import OtherLocations from "@/components/Services/Steps/2.1OtherLocations.vue";

export default {
  name: "LocationStep",
  components: {
    CountryInputButton,
    SwitchesCheckboxes,
    SwitchCheckbox,
    OtherLocations,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => ({
        country: "",
        region: "",
        city: "",
        zip: "",
        address: "",
        hasOtherLocations: false,
        otherLocations: [],
        hasInternationalCoverage: false,
        internationalCoverage: {},
      }),
    },
  },
  data() {
    return {
      currentInputIndex: -1, // Start with country input
      locationInputs: [
        {
          name: "region",
          icon: "fa-solid fa-location-crosshairs",
          pattern: "[a-zA-ZÀ-ž\\s\\-]+",
        },
        {
          name: "city",
          icon: "fa-solid fa-city",
          pattern: "[a-zA-ZÀ-ž\\s\\-]+",
        },
        {
          name: "zip",
          icon: "fa-solid fa-envelope",
          pattern: "[A-Za-z0-9\\s\\-]{3,20}",
        },
        {
          name: "address",
          icon: "fa-solid fa-house",
          pattern: "[a-zA-Z0-9À-ž\\s,.'\\-]{5,200}",
        },
      ],
      internationalCoverageOptions: [
        { name: "europe", label: "Europe", value: false },
        { name: "asia", label: "Asia", value: false },
        { name: "northAmerica", label: "North America", value: false },
        { name: "southAmerica", label: "South America", value: false },
        { name: "africa", label: "Africa", value: false },
      ],
      location: { ...this.modelValue },
      maxOtherLocations: 5,
    };
  },
  computed: {
    currentInput() {
      if (this.currentInputIndex === -1) return "country";
      if (this.currentInputIndex < this.locationInputs.length) {
        return this.locationInputs[this.currentInputIndex]?.name;
      }
      if (this.currentInputIndex === this.locationInputs.length) {
        return "hasOtherLocations";
      }
      return "hasInternationalCoverage"; // When currentInputIndex exceeds locationInputs length
    },
    isStepComplete() {
      return (
        this.location.country &&
        this.location.region &&
        this.location.city &&
        this.location.zip &&
        this.location.address
      );
    },
  },
  watch: {
    currentInputIndex(newIndex) {
      this.$nextTick(() => {
        if (newIndex === -1) {
          this.focusInputById("countryInput");
        } else {
          const inputName = this.locationInputs[newIndex]?.name;
          this.focusInputById(inputName);
        }
      });
    },
    modelValue: {
      deep: true,
      handler(newVal) {
        this.location = { ...newVal };
      },
    },
  },
  methods: {
    onCountrySelected(country) {
      this.location.country = country;
      this.currentInputIndex = 0; // Show next input
    },
    nextInput() {
      if (this.currentInputIndex < this.locationInputs.length) {
        this.currentInputIndex += 1;
      } else if (this.currentInputIndex === this.locationInputs.length) {
        this.currentInputIndex += 1; // Move to hasInternationalCoverage
      }
    },
    showAdditionalOptions() {
      if (this.currentInputIndex === this.locationInputs.length - 1) {
        this.currentInputIndex += 1; // Move to additional options
      }
    },
    nextStep() {
      this.$emit("update:location", this.location);
      this.$emit("change-step", "dimensions");
    },
    isValidInput(value, pattern) {
      if (!value || !pattern) return false;
      const regex = new RegExp(pattern);
      return regex.test(value);
    },
    focusInputById(id) {
      const input = document.getElementById(id);
      if (input) input.focus();
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.countryInput?.$el.querySelector("input")?.focus();
    });
  },
};
</script>

<style scoped>
input {
  border-left: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-right: 0px !important;
  border-color: #696969;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}
input:focus:placeholder-shown {
  border-color: #0080ff !important;
  box-shadow:
    0 4px 6px -1px rgb(0 128 255 / 0.25),
    0 2px 4px -2px rgb(0 128 255 / 0.25) !important;
}
input:focus:invalid {
  border-color: #f7b500;
  box-shadow:
    0 4px 6px -1px rgb(247 181 0 / 0.25),
    0 2px 4px -2px rgb(247 181 0 / 0.25);
}
input:not(:focus):user-invalid {
  border-color: #e60000;
}
input:user-valid {
  border-color: #00d112 !important;
}
input:focus:user-valid {
  border-color: #00d112 !important;
  box-shadow:
    0 4px 6px -1px rgb(0 209 18 / 0.25),
    0 2px 4px -2px rgb(0 209 18 / 0.25) !important;
}
input:valid {
  border-color: #00d112 !important;
}
input:focus:valid {
  border-color: #00d112 !important;
  box-shadow:
    0 4px 6px -1px rgb(0 209 18 / 0.25),
    0 2px 4px -2px rgb(0 209 18 / 0.25) !important;
}
</style>
