<!-- eslint-disable prettier/prettier -->
<template>
  <div class="relative mx-auto dimensions-step p-4">
    <!-- Step Title -->
    <span class="block text-2xl font-semibold text-brand-darkblue text-center">
      <i class="fa-solid fa-ruler-combined mr-2"></i>
      3rd Step: Dimensions
    </span>
    <span
      class="block mt-2 text-base font-normal text-brand-darkblue text-center"
    >
      (Provide the dimensions for your space)
    </span>

    <!-- Dynamic Inputs -->
    <div v-for="(input, index) in dimensionsInputs" :key="input.name">
      <div
        class="input-container mx-auto mt-4 flex flex-col relative max-w-md sm:max-w-full"
        v-if="currentInputIndex === index"
      >
        <label
          :for="input.name"
          class="text-brand-darkblue font-medium text-lg mb-4"
        >
          <i :class="input.icon" class="mr-1"></i>
          {{ input.name.charAt(0).toUpperCase() + input.name.slice(1) }}
        </label>
        <div class="rounded-l-lg flex items-center">
          <input
            type="number"
            :ref="`dimensionInput${index}`"
            class="w-full py-3 px-4 rounded-l-lg"
            :value="dimensions[input.name] === 0 ? '' : dimensions[input.name]"
            @input="
              applyInputRestrictions($event, input),
              updateDimension($event, input.name)
            "
            @keydown.enter.prevent="handleEnter(index)"
            :id="input.name"
            :placeholder="input.placeholder"
            required
            :minlength="input.minlength"
            :maxlength="input.maxlength || 10"
            :pattern="input.pattern"
          />
          <button
            type="button"
            :disabled="!isValidInput(dimensions[input.name], input.pattern)"
            v-if="currentInputIndex < dimensionsInputs.length - 1"
            @click="nextInput"
            class="py-3 px-4 bg-brand-darkblue text-brand-yellow border border-brand-darkblue rounded-r-lg hover:bg-brand-yellow hover:text-brand-darkblue disabled:bg-grey-light disabled:text-brand-darkblue disabled:cursor-not-allowed hover:disabled:bg-grey-light hover:disabled:text-brand-darkblue"
          >
            <i class="fa-solid fa-arrow-right"></i>
          </button>
          <button
            type="button"
            :disabled="!isValidInput(dimensions[input.name], input.pattern)"
            v-if="currentInputIndex === dimensionsInputs.length - 1"
            @click="nextStep"
            class="py-3 px-4 bg-brand-darkblue text-brand-yellow border border-brand-darkblue rounded-r-lg hover:bg-brand-yellow hover:text-brand-darkblue disabled:bg-grey-light disabled:text-brand-darkblue disabled:cursor-not-allowed hover:disabled:bg-grey-light hover:disabled:text-brand-darkblue"
          >
            <i class="fa-solid fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DimensionsStep",
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dimensions: { ...this.modelValue },
      dimensionsInputs: [
        {
          name: "surface",
          icon: "fa-solid fa-maximize",
          placeholder: "Enter surface (in m2)",
          pattern: "^[0-9]{3,6}$",
          minlength: 3,
          maxlength: 6,
          restriction: "restrictToDigits",
        },
        {
          name: "height",
          icon: "fa-solid fa-arrows-up-down",
          placeholder: "Enter height (in m, you can use decimals)",
          pattern: "^[0-9]{1,5}(\\.[0-9]{1,2})?$", // Numeric with optional 2 decimal places
          maxlength: 5,
          restriction: "restrictToDecimal",
        },
        {
          name: "palletPositions",
          icon: "fa-solid fa-pallet",
          placeholder: "Enter height (in m)",
          pattern: "^[0-9]+$",
          maxlength: 7,
          restriction: "restrictToDigits",
        },
      ],
      currentInputIndex: 0, // Start with the first input
    };
  },
  watch: {
    currentInputIndex(newIndex) {
      this.$nextTick(() => {
        const inputName = this.dimensionsInputs[newIndex]?.name;
        this.focusInputById(inputName);
      });
    },
  },
  computed: {
    currentInput() {
      return this.dimensionsInputs[this.currentInputIndex]?.name;
    },
  },
  methods: {
    updateDimension(event, inputName) {
      const value = event.target.value;
      if (inputName === "height") {
        // Parse as float for height
        this.dimensions[inputName] = value === "" ? 0 : parseFloat(value);
      } else {
        // Parse as integer for surface and palletPositions
        this.dimensions[inputName] = value === "" ? 0 : parseInt(value);
      }
    },
    handleEnter(index) {
      if (index < this.dimensionsInputs.length - 1) {
        // Move to the next input
        this.nextInput();
      } else {
        // Trigger next step if on the last input
        this.nextStep();
      }
    },
    nextInput() {
      if (this.currentInputIndex < this.dimensionsInputs.length - 1) {
        this.currentInputIndex += 1;
      }
    },
    nextStep() {
      console.log(this.dimensions);
      this.$emit("update:dimensions", this.dimensions);
      this.$emit("change-step", "storageAndInventory");
    },
    isValidInput(value, pattern) {
      if (!value || !pattern) return false;
      const regex = new RegExp(pattern);
      return regex.test(value);
    },
    focusInputById(id) {
      const input = document.getElementById(id);
      if (input) input.focus();
    },
    applyInputRestrictions(event, input) {
      if (input.restriction === "restrictToDigits") {
        this.restrictToDigits(event);
      } else if (input.restriction === "restrictToDecimal") {
        this.restrictToDecimal(event);
      }
    },
    restrictToDigits(event) {
      const input = event.target;
      input.value = input.value.replace(/\D/g, ""); // Remove all non-digit characters
    },
    restrictToDecimal(event) {
      const input = event.target;
      input.value = input.value
        .replace(/,/g, ".") // Replace commas with dots
        .replace(/[^0-9.]/g, "") // Allow only digits and dots
        .replace(/(\.)(?=.*\.)/g, ""); // Allow only one dot
    },
  },
  mounted() {
    this.$nextTick(() => {
      const firstInput = this.dimensionsInputs[0]?.name;
      this.focusInputById(firstInput);
    });
  },
};
</script>

<style scoped>
input {
  border-left: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-right: 0px !important;
  border-color: #696969;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}
input:focus:placeholder-shown {
  border-color: #0080ff !important;
  box-shadow:
    0 4px 6px -1px rgb(0 128 255 / 0.25),
    0 2px 4px -2px rgb(0 128 255 / 0.25) !important;
}
input:focus:invalid {
  border-color: #f7b500;
  box-shadow:
    0 4px 6px -1px rgb(247 181 0 / 0.25),
    0 2px 4px -2px rgb(247 181 0 / 0.25);
}
input:not(:focus):user-invalid {
  border-color: #e60000;
}
input:user-valid {
  border-color: #00d112 !important;
}
input:focus:user-valid {
  border-color: #00d112 !important;
  box-shadow:
    0 4px 6px -1px rgb(0 209 18 / 0.25),
    0 2px 4px -2px rgb(0 209 18 / 0.25) !important;
}
input:valid {
  border-color: #00d112 !important;
}
input:focus:valid {
  border-color: #00d112 !important;
  box-shadow:
    0 4px 6px -1px rgb(0 209 18 / 0.25),
    0 2px 4px -2px rgb(0 209 18 / 0.25) !important;
}
</style>
