import apiClient from "@/api";
import { marked } from "marked"; // Changed import style

export default {
  async fetchPosts({ commit }) {
    commit("SET_LOADING", true);
    try {
      const { data } = await apiClient.get("/api/posts");
      const processedPosts = data.map((post) => ({
        ...post,
        content: marked.parse(post.content), // Added .parse() for modern marked
      }));
      commit("SET_POSTS", processedPosts);
      return processedPosts;
    } catch (error) {
      commit(
        "SET_ERROR",
        error.response?.data?.message || "Failed to load posts",
      );
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async fetchPost({ commit, state }, slug) {
    commit("SET_LOADING", true);
    try {
      const existingPost = state.posts.find((p) => p.slug === slug);
      if (existingPost) {
        commit("SET_CURRENT_POST", existingPost);
        return existingPost;
      }

      const { data } = await apiClient.get(`/api/posts/${slug}`);
      const processedPost = {
        ...data,
        content: marked.parse(data.content), // Added .parse()
      };
      commit("ADD_POST", processedPost);
      commit("SET_CURRENT_POST", processedPost);
      return processedPost;
    } catch (error) {
      commit("SET_ERROR", error.response?.data?.message || "Post not found");
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
};
