<template>
  <div
    class="bg-white mt-5 mx-auto p-5 rounded-md max-w-md text-center shadow-md"
  >
    <h1 class="font-semibold text-brand-darkblue text-xl px-4 py-3">
      <i class="fa-solid fa-user-shield mr-1"></i>Password Forgotten
    </h1>
    <p class="font-medium text-darkgrey mt-5 text-" v-if="!emailSent">
      Enter your email below, and we'll send you a link to reset your password.
    </p>

    <div v-if="!emailSent" class="mt-4">
      <input
        type="email"
        v-model="email"
        class="border border-brand-darkblue rounded-md px-4 py-3 w-full text-black my-4"
        placeholder="Enter your email"
        :disabled="loading"
      />
      <button
        class="bg-brand-darkblue text-brand-yellow border font-medium border-brand-darkblue mt-2 px-4 py-2 rounded hover:bg-brand-yellow hover:text-brand-darkblue disabled:bg-grey-light disabled:text-brand-darkblue disabled:cursor-not-allowed"
        @click="sendResetEmail"
        :disabled="loading || !isValidEmail"
      >
        {{ loading ? "Sending..." : "Send Reset Email" }}
      </button>
    </div>

    <p
      v-if="message"
      class="mt-4 font-medium"
      :class="{ 'text-status-success': !isError, 'text-status-error': isError }"
    >
      {{ message }}
    </p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PasswordForgotten",
  data() {
    return {
      email: "", // User email input
      loading: false, // Button loading state
      message: "", // Feedback message
      isError: false, // Whether the feedback is an error
      emailSent: false, // Track if the reset email was sent
    };
  },
  computed: {
    // Computed property to validate the email
    isValidEmail() {
      const emailPattern = /^[^\s@+]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.email);
    },
  },
  methods: {
    async sendResetEmail() {
      this.loading = true; // Start loading state
      this.message = ""; // Reset any previous message

      try {
        console.log("Sending request with email:", this.email); // Debug log
        const response = await axios.post("/users/forgot-password", {
          email: this.email,
        });

        this.message = response.data.message; // Set success message
        this.isError = false; // Not an error
        this.emailSent = true; // Mark email as sent
      } catch (error) {
        console.error("Error response:", error.response);
        console.error("Error message:", error.message);
        console.error("Error config:", error.config);
        this.message =
          error.response?.data?.error || "An error occurred. Please try again.";
        this.isError = true; // Mark as error
      } finally {
        this.loading = false; // Stop loading state
      }
    },
  },
};
</script>

<style scoped></style>
