<template>
  <div @blur="hideCountryList" class="relative block mx-auto my-4 pl-0">
    <p class="text-brand-darkblue font-medium text-lg mb-4">
      <i class="fa-solid fa-flag mr-1"></i>
      Country
    </p>
    <div class="flex mt-4 rounded-lg" @blur="hideCountryList">
      <input
        ref="countryInput"
        autocapitalize="on"
        autocorrect="off"
        autocomplete="off"
        type="text"
        v-model="countrySearchQuery"
        @input="filterCountries"
        @keydown="navigateCountryList"
        placeholder="Enter the country"
        id="country-input"
        class="rounded-l-lg w-full px-4 py-3 mr-px"
        :class="{
          'country-not-selected': isCountryNotSelected,
          'country-selected': isCountrySelected,
        }"
        minlength="4"
        required
      />
      <button
        :disabled="!isCountrySelected"
        @click="handleSubmit"
        id="submit-country"
        class="py-3 px-4 bg-brand-darkblue text-brand-yellow border border-brand-darkblue rounded-r-lg hover:bg-brand-yellow hover:text-brand-darkblue disabled:bg-grey-light disabled:text-brand-darkblue disabled:cursor-not-allowed hover:disabled:bg-grey-light hover:disabled:text-brand-darkblue"
      >
        <i class="fa-solid fa-arrow-right"></i>
      </button>
    </div>
    <ul
      id="country-list"
      ref="countryList"
      v-if="isCountryListVisible && filteredCountries.length > 0"
    >
      <li
        v-for="(country, index) in filteredCountries"
        :key="country"
        :class="{ selected: index === selectedCountryIndex }"
        @click="selectCountry(country)"
      >
        {{ country }}
      </li>
    </ul>
  </div>
</template>

<script>
import countries from "@/assets/location/countries/countries.json";

export default {
  name: "CountryInputButton",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      countrySearchQuery: this.value || "",
      filteredCountries: [],
      selectedCountryIndex: -1,
      isCountryListVisible: false,
    };
  },
  computed: {
    isCountrySelected() {
      return countries.includes(this.countrySearchQuery);
    },
    isCountryNotSelected() {
      return this.countrySearchQuery.length > 0 && !this.isCountrySelected;
    },
  },
  methods: {
    filterCountries() {
      if (this.countrySearchQuery.length > 0) {
        this.filteredCountries = countries.filter((country) =>
          country
            .toLowerCase()
            .startsWith(this.countrySearchQuery.toLowerCase()),
        );
        this.selectedCountryIndex = -1;
        this.isCountryListVisible = true;
      } else {
        this.filteredCountries = [];
        this.isCountryListVisible = false;
      }
    },
    hideCountryList() {
      this.isCountryListVisible = false;
    },
    selectCountry(country) {
      this.countrySearchQuery = country;
      this.filteredCountries = [];
      this.isCountryListVisible = false;
      this.selectedCountryIndex = -1;
    },
    handleSubmit() {
      if (this.isCountrySelected) {
        this.$emit("update:modelValue", this.countrySearchQuery);
        this.$emit("country-selected", this.countrySearchQuery);
      }
    },
    scrollToSelectedCountry() {
      this.$nextTick(() => {
        const list = this.$refs.countryList;
        const selectedOption = list.children[this.selectedCountryIndex];
        if (selectedOption) {
          list.scrollTop =
            selectedOption.offsetTop -
            list.clientHeight / 2 +
            selectedOption.clientHeight / 2;
        }
      });
    },

    navigateCountryList(event) {
      if (this.filteredCountries.length > 0) {
        if (event.key === "ArrowDown") {
          event.preventDefault();
          this.selectedCountryIndex =
            (this.selectedCountryIndex + 1) % this.filteredCountries.length;
          this.scrollToSelectedCountry();
        } else if (event.key === "ArrowUp") {
          event.preventDefault();
          this.selectedCountryIndex =
            (this.selectedCountryIndex - 1 + this.filteredCountries.length) %
            this.filteredCountries.length;
          this.scrollToSelectedCountry();
        } else if (event.key === "Enter") {
          event.preventDefault();
          if (this.selectedCountryIndex >= 0) {
            this.selectCountry(
              this.filteredCountries[this.selectedCountryIndex],
            );
          } else {
            this.handleSubmit(); // Submit if no country is highlighted.
          }
        } else if (event.key === "Escape") {
          this.hideCountryList();
          this.selectedCountryIndex = -1;
        }
      } else if (event.key === "Enter") {
        this.handleSubmit(); // Submit if dropdown is not visible.
      }
    },
  },

  watch: {
    value(newVal) {
      this.countrySearchQuery = newVal;
    },
  },
};
</script>

<style scoped>
#country-list {
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  width: 100%;
  z-index: 999;
  max-height: 318px;
  overflow-y: auto;
  background-color: white;
  border-top: none;
  border-left: 1px solid #004aad;
  border-bottom: 1px solid #004aad;
  border-right: 1px solid #004aad;
  border-radius: 8px;
  list-style-type: none;
}
ul li {
  padding: 0.67rem 1rem;
  background: white;
}

ul li:hover,
ul .selected {
  background-color: #004aad;
  color: #efb615;
  cursor: pointer;
}

ul li:first-child {
  border-radius: 8px 8px 0 0;
}
ul li:last-child {
  border-radius: 0 0 8px 8px;
}
ul li:first-child:last-child {
  border-radius: 8px;
}

input {
  border-left: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-right: 0px;
  border-color: #696969;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}
input:focus:placeholder-shown {
  border-color: #0080ff !important;
  box-shadow:
    0 4px 6px -1px rgb(0 128 255 / 0.25),
    0 2px 4px -2px rgb(0 128 255 / 0.25) !important;
}
.country-not-selected:focus {
  border-color: #f7b500 !important;
  box-shadow:
    0 4px 6px -1px rgb(247 181 0 / 0.25),
    0 2px 4px -2px rgb(247 181 0 / 0.25) !important;
}

.country-not-selected {
  border-color: #e60000 !important;
}
.country-selected {
  border-color: #00d112 !important;
}
.country-selected:focus {
  border-color: #00d112 !important;
  box-shadow:
    0 4px 6px -1px rgb(0 209 18 / 0.25),
    0 2px 4px -2px rgb(0 209 18 / 0.25) !important;
}
</style>
