export default () => ({
  isAuthenticated: false,
  user: null,
  userLogoBlob: null,
  failedPasswordAttempts: 0,
  lastPasswordAttempt: null,
  logoutTimer: null,
  csrfToken: null,
});

// Helper function remains here since it's auth-specific
export function getStoredUser() {
  try {
    const user = localStorage.getItem("user");
    return user ? JSON.parse(user) : null;
  } catch (error) {
    console.error("Error parsing stored user:", error);
    localStorage.removeItem("user");
    return null;
  }
}
