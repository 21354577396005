import axios from "axios";

const apiClient = axios.create({
  baseURL: "/api", // Use relative path to avoid CORS issues
  withCredentials: true,
});

// Add a response interceptor to catch and log errors
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("API call failed: ", error.response?.data || error.message);
    return Promise.reject(error);
  },
);

export default apiClient;
