<template>
  <div
    class="my-8 max-w-lg bg-surfaceSaturated md:p-4 min-md:p-8 m-auto mb-4 rounded-lg shadow-status-info"
  >
    <h1
      for="login-form"
      class="text-2xl text-primary font-semibold text-center mx-auto mt-2.5 mb-6.5"
    >
      <i class="fa-solid fa-sign-in-alt fa-lg"></i>
      User Login
    </h1>
    <form id="login-form" @submit.prevent="handleSubmit">
      <label for="email" class="font-semibold mb-2 block text-primary"
        >E-mail</label
      >
      <input
        class="rounded-lg w-full m-auto px-4 border border-primary py-3 text-primary bg-background focus:text-background focus:bg-primary focus:border-status-info outline-none"
        type="email"
        id="email"
        v-model="form.email"
        required
      />

      <div class="password-div mt-4 flex flex-col">
        <label for="password" class="font-semibold mb-2 block text-primary"
          >Password</label
        >
        <div class="flex flex-row">
          <input
            :type="passwordFieldType"
            autocomplete="email"
            class="password w-full m-auto px-4 py-3 border border-r-0 border-primary rounded-l-lg text-primary bg-background focus:text-background focus:bg-primary focus:border-status-info outline-none"
            name="password"
            id="password"
            v-model="form.password"
            required
          />
          <button
            type="button"
            class="min-w-[50px] py-3 px-4 bg-brand-yellow text-brand-darkblue rounded-r-lg border border-brand-yellow br-0 hover:bg-brand-darkblue hover:text-brand-yellow"
            @click="togglePassword"
          >
            <i :class="passwordIcon"></i>
          </button>
        </div>
      </div>

      <router-link
        to="/forgotten-password"
        target="_blank"
        class="block font-semibold mt-6 text-brand-yellow hover:underline max-w-fit"
        >Forgot your password?</router-link
      >

      <button
        type="submit"
        class="w-full mt-10 py-3 px-4 bg-brand-yellow text-brand-darkblue border border-brand-yellow rounded-lg font-semibold text-lg hover:bg-brand-darkblue hover:text-brand-yellow transition-colors duration-200 ease-in-out"
      >
        Log-in
      </button>
      <p
        v-if="errorMessage"
        class="error-message text-status-error text-center mt-4"
      >
        {{ errorMessage }}
      </p>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "UserLogin",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      passwordFieldType: "password",
      passwordIcon: "fa-solid fa-eye-slash",
      errorMessage: null,
    };
  },
  methods: {
    ...mapActions(["login"]),
    async handleSubmit() {
      try {
        this.errorMessage = null;

        await this.login({
          email: this.form.email,
          password: this.form.password,
        });

        // Redirect to the user's account page
        this.$router.push({ name: "your-account" });
      } catch (error) {
        this.errorMessage = error.message || "An error occurred during login.";
      }
    },
    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordIcon =
        this.passwordFieldType === "password"
          ? "fa-solid fa-eye-slash"
          : "fa-solid fa-eye";
    },
  },
};
</script>

<style scoped></style>
