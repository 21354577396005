export default {
  SET_PASSWORD_ATTEMPT(state, payload) {
    state.failedPasswordAttempts = payload.attempts;
    state.lastPasswordAttempt = payload.timestamp;
  },

  SET_USER(state, { user }) {
    state.user = user;
    state.isAuthenticated = !!user;
    localStorage.setItem("user", JSON.stringify(user));
  },

  SET_CSRF_TOKEN(state, token) {
    state.csrfToken = token;
  },

  LOGOUT(state) {
    state.user = null;
    state.isAuthenticated = false;
    state.userLogoBlob = null;
    state.csrfToken = null;

    if (state.logoutTimer) {
      clearTimeout(state.logoutTimer);
      state.logoutTimer = null;
    }

    localStorage.removeItem("user");
    localStorage.removeItem("_csrf");
  },

  SET_USER_LOGO_BLOB(state, blob) {
    state.userLogoBlob = blob;
  },
};
