export default {
  allPosts: (state) => state.posts,
  currentPost: (state) => state.currentPost,
  filteredPosts:
    (state) =>
    ({ category, tag }) => {
      return state.posts.filter((post) => {
        const categoryMatch = !category || post.category === category;
        const tagMatch = !tag || post.tags.includes(tag);
        return categoryMatch && tagMatch;
      });
    },
  isLoading: (state) => state.isLoading,
  error: (state) => state.error,
};
