export default {
  SET_POSTS(state, posts) {
    state.posts = posts;
  },
  SET_CURRENT_POST(state, post) {
    state.currentPost = post;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  ADD_POST(state, post) {
    const existingIndex = state.posts.findIndex((p) => p.slug === post.slug);
    if (existingIndex === -1) {
      state.posts.push(post);
    } else {
      state.posts.splice(existingIndex, 1, post);
    }
  },
};
