import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import axios from "axios";
import NotFoundPage from "@/views/NotFoundPage.vue";
import HomePage from "@/views/HomePage.vue";
import WebflowLandingPage from "@/views/WebflowLandingPage.vue";
import LogInPage from "@/views/LogInPage.vue";
import UserRegistry from "@/views/UserRegistry.vue";
import MapResultsPage from "@/views/MapResultsPage.vue";
import ServicesSearchView from "@/views/ServicesSearchView.vue";
import ServiceSearchDetailsView from "@/views/ServiceSearchDetailsView.vue";
import RegisterSpaceOrServiceView from "@/views/RegisterSpaceOrService.vue";
import SpaceRegistry from "@/views/SpaceRegistry.vue";
import ServiceRegistry from "@/views/ServiceRegistry.vue";
import MyServiceDetailsView from "@/views/MyServiceDetailsView.vue";
import YourAccountView from "@/views/YourAccount.vue";
import SpaceDetailsView from "@/views/SpaceDetailsView.vue";
import UserVerificationView from "@/views/UserVerification.vue";
import ForgottenPassowrd from "@/views/ForgottenPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import Dropzone from "@/components/common/Dropzone.vue";
import ImageView from "@/components/common/Image.vue";
import BlogView from "@/views/Blog.vue";
import OurMission from "@/views/OurMission.vue";

const routes = [
  {
    path: "/landing1.0",
    name: "home",
    component: HomePage,
    meta: {
      title: "Biglo | The Place to Find Your Space",
    },
  },
  {
    path: "/",
    name: "webflow-landing-page",
    component: WebflowLandingPage,
    meta: {
      title: "Biglo | The Place to Find Your Space",
    },
  },
  {
    path: "/log-in",
    name: "log-in",
    component: LogInPage,
    meta: {
      title: "Log In | Biglo",
    },
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: UserRegistry,
    meta: {
      title: "User Registry | Biglo",
    },
  },
  {
    path: "/map-search",
    name: "map-search",
    component: MapResultsPage,
    meta: {
      title: "Map Search | Biglo",
    },
  },
  {
    path: "/services-search",
    name: "services-search",
    component: ServicesSearchView,
    meta: {
      title: "Services Search | Biglo",
    },
  },
  {
    path: "/services/:serviceId",
    name: "ServiceSearchDetails",
    component: ServiceSearchDetailsView,
    meta: {
      title: "Service Details | Biglo",
    },
  },
  {
    path: "/register-space-or-service",
    name: "register-space-or-service",
    component: RegisterSpaceOrServiceView,
    meta: {
      title: "Register Space or Service | Biglo",
      requiresAuth: false,
    },
  },
  {
    path: "/space-registry",
    name: "space-registry",
    component: SpaceRegistry,
    meta: {
      title: "Register your Space | Biglo",
      requiresAuth: true,
    },
  },
  {
    path: "/service-registry",
    name: "service-registry",
    component: ServiceRegistry,
    meta: {
      title: "Register your Service | Biglo",
      requiresAuth: true,
    },
  },
  {
    path: "/your-account",
    name: "your-account",
    component: YourAccountView,
    meta: {
      title: "Your Account | Biglo",
      requiresAuth: true,
    },
  },
  {
    path: "/spaces/:spaceId",
    name: "SpaceDetails",
    component: SpaceDetailsView,
    meta: {
      title: "Space Details | Biglo",
    },
  },
  {
    path: "/my-services/:serviceId",
    name: "MyServiceDetails",
    component: MyServiceDetailsView,
    meta: {
      title: "Service Details | Biglo",
    },
  },
  {
    path: "/verify-email",
    name: "email-verification",
    component: UserVerificationView,
    meta: {
      title: "User Verification | Biglo",
    },
  },
  {
    path: "/forgotten-password",
    name: "forgotten-password",
    component: ForgottenPassowrd,
    meta: {
      title: "Forgotten Password | Biglo",
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword,
    meta: {
      title: "Reset Password | Biglo",
    },
  },
  {
    path: "/upload-file",
    name: "upload-file",
    component: Dropzone,
    meta: {
      title: "Upload File | Biglo",
    },
  },
  {
    path: "/images/:imageId",
    name: "image-view",
    component: ImageView,
    meta: {
      title: "Biglo",
    },
  },
  {
    path: "/blog",
    name: "Blog",
    component: BlogView,
    meta: {
      title: "Blog | Biglo",
      requiresAuth: false,
    },
    children: [
      {
        path: "",
        name: "BlogIndex",
        component: () => import("@/components/content/blog/BlogIndex.vue"),
      },
      {
        path: "categories/:category",
        name: "BlogCategory",
        component: () => import("@/components/content/blog/BlogIndex.vue"),
        props: true,
      },
      {
        path: "tags/:tag",
        name: "BlogTag",
        component: () => import("@/components/content/blog/BlogIndex.vue"),
        props: true,
      },
      {
        path: ":slug",
        name: "BlogPost",
        component: () => import("@/components/content/blog/BlogPost.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/our-mission",
    name: "our-mission",
    component: OurMission,
    meta: {
      title: "Our Mission | Biglo",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found-page",
    component: NotFoundPage,
    metaInfo: {
      title: "404: Page not found | Biglo",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Axios response interceptor for error handling
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Check if the response exists before accessing its status
    if (error.response) {
      if (error.response.status === 401) {
        // Handle unauthorized access
        store.commit("LOGOUT"); // Clear user state
        router.push("/log-in");
      }
    } else {
      // Handle other types of errors (e.g., network errors)
      console.error("An error occurred:", error.message);
    }
    return Promise.reject(error);
  },
);

// Axios response interceptor for error handling
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Check if the response exists before accessing its status
    if (error.response) {
      if (error.response.status === 401) {
        // Handle unauthorized access
        router.push("/log-in");
      }
    } else {
      // Handle other types of errors (e.g., network errors)
      console.error("An error occurred:", error.message);
    }
    return Promise.reject(error);
  },
);

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.getters.isAuthenticated
  ) {
    next({
      path: "/log-in",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

export default router;
