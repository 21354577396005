<template>
  <header class="header" :class="{ scrolled: isScrolled }">
    <div class="header-content text-primary">
      <router-link
        to="/"
        class="flex align-center text-primary hover:text-inverted transition-colors duration-200 ease-in-out"
      >
        <img
          class="logoimg"
          src="@/assets/images/biglo_logo.png"
          alt="Logo image of Biglo"
          width="40px"
          height="40px"
        />
        <h1 id="logo_name" class="pl-2 text-4xl font-medium mx-auto">Biglo</h1>
      </router-link>

      <input type="checkbox" id="check" class="hidden-checkbox" />
      <label for="check" class="checkbtn">
        <i class="fas fa-bars"></i>
      </label>
      <ul class="nav-links">
        <li class="nav-item">
          <router-link
            v-if="!isAuthenticated"
            to="/sign-up"
            class="register text-primary hover:text-brand-yellow"
          >
            <i class="fa-solid fa-user-plus"></i>
            Sign Up
          </router-link>
          <router-link
            v-else
            to="/register-space-or-service"
            class="register text-primary hover:text-brand-yellow"
          >
            <i class="fa-solid fa-thumbtack"></i>
            Register space/service!
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            v-if="!isAuthenticated"
            to="/log-in"
            class="sign_in text-primary hover:text-brand-yellow"
          >
            <i class="fa-solid fa-user"></i>
            Log In
          </router-link>
          <router-link
            v-else-if="!isOnYourAccount"
            to="/your-account"
            class="your_account text-primary hover:text-brand-yellow"
          >
            <i class="fa-solid fa-circle-user"></i>
            Your Account
          </router-link>
          <button
            v-else
            @click="handleLogout"
            class="logout text-primary hover:text-brand-yellow"
          >
            <i class="fa-solid fa-arrow-right-from-bracket"></i>
            Log Out
          </button>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TransparentHeader",
  data() {
    return {
      isScrolled: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    isOnYourAccount() {
      return this.$route.path === "/your-account";
    },
  },
  methods: {
    ...mapActions(["logout"]),
    handleLogout() {
      this.logout();
      this.$router.push("/");
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: transparent;
  transition: background-color 0.5s ease-out;
}

header.scrolled {
  background-color: #004aad; /* Change this to your desired background color */
}

.header-content {
  max-width: 90vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  height: 86px;
  transition: all 0.2s ease-in-out;
  padding: 16px 0;
}

.hidden-checkbox {
  display: none;
}

.checkbtn {
  cursor: pointer;
  display: none;
  font-size: 24px;
}

.fa-solid {
  margin-right: 0.33rem;
}

@media (max-width: 768px) {
  .checkbtn {
    display: block;
  }
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.nav-item {
  margin-left: 1.25rem;
}

.register,
.sign_in,
.your_account,
.logout {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 9999px;
  padding: 0.625rem 1.25rem;
  height: 2.5rem;
  cursor: pointer;
  transition:
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
}

@media (max-width: 768px) {
  .nav-links {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 86px;
    right: -100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    z-index: 10;
    background-color: #061656;
    transition: right 0.5s ease-out;
  }

  #check:checked ~ .nav-links {
    right: 0;
  }

  .nav-item {
    margin-top: 2rem;
  }

  .register,
  .sign_in,
  .your_account,
  .logout {
    color: #efb615;
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
    margin-top: 1rem;
  }
}
</style>
