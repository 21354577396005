<template>
  <div class="text-brand-darkblue p-5 max-w-screen-xl mx-auto">
    <h1
      class="text-center text-brand-darkblue mb-5 text-2xl border-b-2 border-brand-darkblue pb-2 font-semibold"
    >
      <i class="fa-solid fa-shop-lock"></i>
      Spaces Management
    </h1>
    <div
      v-if="spaces.length"
      class="space-list flex flex-wrap gap-5 justify-between"
    >
      <div
        v-for="space in spaces"
        :key="space._id"
        class="space-item bg-white border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition-transform duration-300 ease-in-out"
        @click="viewDetails(space)"
      >
        <img
          :src="getSpaceImage(space)"
          alt="Space Image"
          class="space-image w-full h-48 object-cover rounded-t-lg"
        />
        <div class="space-details p-4 flex flex-col justify-center w-full">
          <h2 class="text-brand-darkblue mb-2 text-lg">
            {{ space.typeData.type }} space
          </h2>
          <p class="text-black mb-1">
            {{ space.locationData.city }}, {{ space.locationData.country }}
          </p>
          <p class="text-black text-base">
            {{ space.dimensionsData.surface }}m²
          </p>
        </div>
      </div>
    </div>
    <div
      v-else
      class="no-spaces text-center text-black text-lg p-5 bg-gray-100 rounded-lg shadow-lg mt-5"
    >
      No spaces registered for this account.
    </div>
    <router-link
      to="/space-registry"
      class="block mt-6 border border-brand-darkblue text-center bg-brand-darkblue text-brand-yellow font-semibold py-3 px-6 rounded-lg shadow-md hover:bg-brand-yellow hover:text-brand-darkblue w-fit shadow"
    >
      <i class="fa-solid fa-plus mr-2"></i>
      Register New Space
    </router-link>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      spaces: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["fetchUser"]),
    async fetchSpaces() {
      if (!this.user) {
        console.error("No user found. Please log in.");
        return;
      }

      try {
        const response = await axios.get(`/spaces/user/${this.user._id}`);
        this.spaces = response.data;
      } catch (error) {
        console.error("Error loading spaces:", error.message);
      }
    },
    getSpaceImage(space) {
      if (
        space.multimediaData.images &&
        space.multimediaData.images.length > 0
      ) {
        return space.multimediaData.images[0];
      }
      return require("@/assets/images/free-advertisement.png");
    },
    viewDetails(space) {
      this.$router.push({
        name: "SpaceDetails",
        params: { spaceId: space._id },
      });
    },
  },
  mounted() {
    this.fetchSpaces();
  },
};
</script>
<style scoped></style>
