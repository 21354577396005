<template>
  <CommonHeader />
  <div
    class="flex flex-col justify-center items-center min-h-[calc(100svh-342px)]"
  >
    <img
      src="@/assets/images/biglo_logo.png"
      class="w-48 mx-auto"
      alt="Biglo's Logo"
    />
    <h1
      class="text-3xl font-bold text-blue-800 max-w-fit mx-auto text-center flex flex-col mt-6"
    >
      <span>The page you’re looking for</span>
      <span class="block mx-auto">can’t be found.</span>
    </h1>
    <router-link
      to="/"
      class="mt-2 mb-8 border border-blue-800 text-yellow-400 text-xl bg-blue-800 px-3 py-2 font-medium rounded-md shadow-md hover:bg-yellow-400 hover:text-blue-800"
    >
      <i class="fa-solid fa-house-chimney mr-2 ml-1"></i>Want to return home?
    </router-link>
  </div>
  <CommonFooter />
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import CommonFooter from "@/components/common/Footer.vue";
export default {
  name: "NotFoundPage",
  metaInfo: {
    title: "404: Page not found | Biglo",
  },
  components: {
    CommonHeader,
    CommonFooter,
  },
};
</script>
