<template>
  <div class="blog-layout">
    <CommonHeader />
    <main class="container mx-auto px-4 min-md:px-30 py-12">
      <router-view
        :posts="$store.state.blog.posts"
        @category-selected="handleCategorySelect"
        @tag-selected="handleTagSelect"
      />
    </main>
    <CommonFooter />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CommonHeader from "@/components/common/Header.vue";
import CommonFooter from "@/components/common/Footer.vue";

export default {
  name: "BlogView",
  components: { CommonHeader, CommonFooter },
  methods: {
    ...mapActions("blog", ["fetchPosts"]),
    handleCategorySelect(category) {
      this.$router.push({
        path: "/blog/categories/" + encodeURIComponent(category),
      });
    },
    handleTagSelect(tag) {
      this.$router.push({
        path: "/blog/tags/" + encodeURIComponent(tag),
      });
    },
  },
  created() {
    this.fetchPosts();
  },
};
</script>

<style scoped>
.blog-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
}
</style>
