<template>
  <main class="main">
    <h1 class="slogan">
      <span class="slogan-biglo">Biglo.</span>
      <span class="slogan-white">The place to find your logistic</span>
      <span class="slogan-space">space</span>
    </h1>

    <div class="menu">
      <div class="immo-container">
        <h2>
          <span class="text-white">Immo</span
          ><span class="text-brand-yellow">Logistics</span>
        </h2>
        <div class="space-options-container">
          <router-link to="/search-buy" class="router-link">Buy</router-link>
          <router-link to="/search-rent" class="router-link">Rent</router-link>
        </div>
      </div>

      <div class="separator"></div>

      <div class="services-container">
        <h2>
          <span class="text-brand-yellow">Logistics</span
          ><span class="text-white"> Services</span>
        </h2>
        <div class="services-options-container">
          <router-link to="/services-search" class="router-link"
            >Services</router-link
          >
          <router-link to="/services-search" class="router-link"
            >Services</router-link
          >
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "HomeMain",
};
</script>

<style scoped>
.main {
  max-width: 90dvw;
  width: 100%;
  margin: 0 auto;
  height: 67vh;
  background-image: url(@/assets/images/warehouse_background.png);
  background-position: center 30%;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding-top: 50px;
}

.slogan {
  font-size: 2rem;
  font-weight: 700;
  background-color: rgba(0, 74, 173, 0.75);
  border-radius: 0.5rem;
  padding: 0.33rem 0.5rem;
  margin: 32px auto;
  text-align: center;
  width: fit-content;
}

.slogan-biglo {
  color: #efb615;
}

.slogan-white {
  color: #ffffff;
  padding-left: 0.75rem;
  padding-right: 0.5rem;
}

.slogan-space {
  color: #efb615;
}

.menu {
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 74, 173, 0.75);
  border-radius: 0.5rem;
  margin: 0 auto;
  align-items: start;
  justify-content: space-evenly;
  max-width: 720px;
  height: 212px;
}
.menu h2 {
  font-size: 1.875rem;
  font-weight: 600;
}
.immo-container,
.services-container {
  flex: 1;
  padding: 0.625rem;
  margin-top: 16px;
}

.separator {
  width: 2px;
  height: 150px;
  border: 0.125rem solid #ffffff;
  border-radius: 0.5rem;
  margin-top: 2rem;
}

.space-options-container,
.services-options-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.router-link {
  cursor: pointer;
  padding: 0.625rem 1rem;
  border-radius: 0.3125rem;
  background-color: #004ad0;
  border: 1px solid #efb615;
  color: #efb615;
  text-decoration: none;
}

.router-link:hover {
  background-color: #efb615;
  color: #004ad0;
}

.text-brand-yellow {
  color: #efb615;
}

@media (max-width: 806px) {
  .main {
    max-width: 100dvw;
    height: fit-content;
    padding-bottom: 25px;
  }
  .slogan {
    margin-top: -25px;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    width: 20ch;
    max-width: 90svw;
  }

  .slogan > span {
    text-align: center;
  }
  .slogan-white {
    padding: 0 4px 0 6px;
    max-width: 16ch;
  }
  .menu {
    flex-direction: column;
    align-items: center;
    height: min-content;
    max-width: 90svw;
    width: 18.25rem;
    padding-bottom: 25px;
  }
  .menu h2 {
    font-size: 1.67rem;
  }
  .separator {
    height: 2px;
    width: 150px;
    margin: 16px auto 0 auto;
  }
}
</style>
