<template>
  <footer class="bg-brand-darkblue-navy text-white">
    <div class="container mx-auto px-4 py-12">
      <div class="flex items-start mx-auto">
        <!-- Brand Logo and Name -->
        <router-link
          class="flex items-center mb-8 md:mb-0 text-white hover:text-brand-yellow"
          to="/"
        >
          <img
            src="@/assets/images/biglo_logo.png"
            alt="Biglo Logo"
            width="51"
            height="53"
            loading="lazy"
            class="mr-2"
          />
          <div class="text-2xl font-medium">Biglo</div>
        </router-link>

        <!-- Footer Links -->
        <div
          class="grid gap-x-17.5 gap-y-10 grid-cols-[auto_auto_1fr] auto-cols-fr"
        >
          <!-- About Us Section -->
          <div class="mb-8 md:mb-0">
            <h3 class="text-lg font-semibold mb-4">About Us</h3>
            <ul class="space-y-2">
              <li>
                <router-link
                  to="/our-mission"
                  class="hover:text-brand-yellow transition-colors"
                >
                  Our Mission
                </router-link>
              </li>
              <li>
                <router-link
                  to="/our-partners"
                  class="hover:text-brand-yellow transition-colors"
                >
                  Our Partners
                </router-link>
              </li>
            </ul>
          </div>

          <!-- Resources Section -->
          <div class="mb-8 md:mb-0">
            <h3 class="text-lg font-semibold mb-4">Resources</h3>
            <ul class="space-y-2">
              <li>
                <router-link
                  to="/faq"
                  class="hover:text-brand-yellow transition-colors"
                >
                  FAQ
                </router-link>
              </li>
              <li>
                <router-link
                  to="/blog"
                  class="hover:text-brand-yellow transition-colors"
                >
                  Blog
                </router-link>
              </li>
            </ul>
          </div>

          <!-- Biglo Section -->
          <div>
            <h3 class="text-lg font-semibold mb-4">Biglo</h3>
            <ul class="space-y-2">
              <li>
                <router-link
                  to="/terms-and-conditions"
                  class="hover:text-brand-yellow transition-colors"
                >
                  Terms and Conditions
                </router-link>
              </li>
              <li>
                <router-link
                  to="/privacy-policy"
                  class="hover:text-brand-yellow transition-colors"
                >
                  Privacy Policy
                </router-link>
              </li>
            </ul>

            <!-- Social Links -->
            <div class="flex space-x-4 mt-4">
              <a
                href="https://www.linkedin.com/company/biglosolutions/"
                target="_blank"
                rel="noopener noreferrer"
                class="social-icon"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="w-6 h-6"
                >
                  <path
                    d="M22.23 0H1.77C.79 0 0 .77 0 1.72v20.56c0 .95.79 1.72 1.77 1.72h20.46c.98 0 1.77-.77 1.77-1.72V1.72C24 .77 23.21 0 22.23 0zM7.09 20.45H3.56V9h3.53v11.45zM5.32 7.51c-1.15 0-2.08-.94-2.08-2.1s.93-2.1 2.08-2.1c1.15 0 2.08.94 2.08 2.1s-.93 2.1-2.08 2.1zM20.45 20.45h-3.52v-5.57c0-1.33-.02-3.05-1.86-3.05-1.86 0-2.15 1.45-2.15 2.95v5.67h-3.52V9h3.37v1.57h.05c.47-.89 1.61-1.83 3.32-1.83 3.55 0 4.2 2.33 4.2 5.36v6.35z"
                  />
                </svg>
              </a>

              <a
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
                class="social-icon"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="w-6 h-6"
                >
                  <path
                    d="M23.5 6.7s-.2-1.6-.9-2.3c-.8-.9-1.7-.9-2.2-1-3.1-.2-7.8-.2-7.8-.2h-.1s-4.7 0-7.8.2c-.5 0-1.4 0-2.2 1-.7.7-.9 2.3-.9 2.3S0 8.5 0 10.2v3.6c0 1.7.2 3.5.2 3.5s.2 1.6.9 2.3c.8.9 1.8.9 2.2 1 3.1.2 7.8.2 7.8.2s4.7 0 7.8-.2c.5 0 1.4 0 2.2-1 .7-.7.9-2.3.9-2.3s.2-1.8.2-3.5V10.2c0-1.7-.2-3.5-.2-3.5zM9.6 15V9l6.5 3-6.5 3z"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- Divider -->
      <div class="border-t border-brand-darkblue-alpha50 my-8"></div>

      <!-- Copyright -->
      <div class="text-center text-sm text-brand-darkblue-lightest">
        Copyright © 2025 Biglo Solutions
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "CommonFooter",
};
</script>

<style scoped>
.social-icon {
  background-color: transparent;
  transition:
    background-color 0.3s ease-in-out,
    transform 0.3s ease-in-out;
}

.social-icon svg {
  fill: white;
  transition: fill 0.3s ease-in-out;
}

.social-icon:hover svg {
  fill: #efb615; /* Yellow color on hover */
}
</style>
