<template>
  <div class="text-brand-darkblue p-5 max-w-screen-xl mx-auto">
    <!-- Title -->
    <h1
      class="text-center text-brand-darkblue mb-5 text-2xl border-b-2 border-brand-darkblue pb-2 font-semibold"
    >
      <i class="fa-solid fa-search"></i>
      Search Results
    </h1>

    <!-- Results List -->
    <div
      v-if="services.length"
      class="service-list grid gap-6 grid-cols-1 min-lg:grid-cols-2 min-xl:grid-cols-3"
    >
      <div
        v-for="service in services"
        :key="service._id"
        class="service-item bg-white border border-grey-light rounded-lg shadow-md hover:shadow-lg transition-transform duration-200 ease-in-out cursor-pointer"
        @click="viewDetails(service)"
      >
        <!-- Image -->
        <div class="h-48 flex items-center justify-center">
          <img
            v-if="service.imageUrl"
            :src="service.imageUrl"
            alt="Service Image"
            class="service-image w-full h-full object-cover rounded-t-lg"
          />
          <div v-else class="text-grey">No Image Available</div>
        </div>

        <!-- Details -->
        <div class="service-details p-4 flex flex-col gap-2">
          <h2 class="text-brand-darkblue text-lg font-semibold truncate">
            {{ service.userName }}
          </h2>
          <p class="text-black text-sm truncate">
            {{ service.location.city }}, {{ service.location.country }}
          </p>
          <p class="text-grey text-sm truncate">
            Surface: {{ service.dimensions?.surface || "N/A" }} m² | Pallet
            Positions:
            {{ service.dimensions?.palletPositions || "N/A" }}
          </p>
        </div>
      </div>
    </div>

    <!-- No Results -->
    <div
      v-else
      class="no-services text-center text-black text-lg p-5 bg-gray-100 rounded-lg shadow-md mt-5"
    >
      No services found. Try adjusting your search filters.
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ServicesSearchResults",
  data() {
    return {
      services: [], // Holds the list of services fetched based on search criteria
      fallbackImage: require("@/assets/images/service-icon.png"), // Fallback image path
    };
  },
  methods: {
    async fetchServices(filters = {}) {
      // Fetch the services based on filters
      try {
        const response = await axios.get("/services", { params: filters });
        const services = response.data;

        // Fetch image URLs for each service
        for (const service of services) {
          service.imageUrl = await this.getServiceImage(service);
        }

        this.services = services; // Update reactive data
      } catch (error) {
        console.error("Error loading services:", error.message);
      }
    },
    async getServiceImage(service) {
      // Retrieve image URL for a given service
      try {
        const imageId = service?.multimedia?.images?.[0]; // First image ID
        if (!imageId) {
          console.warn("No image ID found. Using fallback image.");
          return this.fallbackImage;
        }

        const response = await axios.get(`/images/${imageId}`, {
          responseType: "blob",
        });

        if (response.status >= 200 && response.status < 300) {
          return URL.createObjectURL(response.data); // Convert blob to URL
        } else {
          console.warn("Failed to fetch service image. Using fallback image.");
          return this.fallbackImage;
        }
      } catch (error) {
        console.error("Error fetching service image:", error?.message || error);
        return this.fallbackImage;
      }
    },
    viewDetails(service) {
      // Navigate to service details
      this.$router.push({
        name: "ServiceSearchDetails",
        params: { serviceId: service._id },
      });
    },
  },
  mounted() {
    // Initial fetch of services
    this.fetchServices();
  },
};
</script>

<style scoped>
.service-list {
  display: grid;
  gap: 1.5rem;
}
.service-item:hover {
  transform: translateY(-4px);
}
</style>
