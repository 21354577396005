<template>
  <div class="relative mx-auto pl-type-step">
    <span class="block text-2xl font-semibold text-brand-darkblue text-center">
      <i class="fa-solid fa-screwdriver-wrench"></i>
      1st Step: PL Type
    </span>
    <span
      class="block mt-2 text-base font-normal text-brand-darkblue text-center"
    >
      (Select all PL types your company offers)
    </span>

    <!-- Switches/Checkboxes component -->
    <SwitchesCheckboxes
      class="relative mx-auto"
      :options="plTypeOptions"
      :model-value="plType"
      @update:modelValue="updatePlTypeData"
    />
    <div class="button-container relative mx-auto w-fit">
      <button
        :disabled="!canProceed"
        class="next-step relative mx-auto rounded-lg mt-5 px-4 py-3 bg-brand-darkblue text-brand-yellow font-medium border border-brand-darkblue hover:bg-brand-yellow hover:text-brand-darkblue disabled:bg-grey-light disabled:text-brand-darkblue disabled:cursor-not-allowed disabled:hover:bg-grey-light disabled:hover:text-brand-darkblue"
        @click="nextStep"
      >
        Next Step
      </button>
    </div>
  </div>
</template>

<script>
import SwitchesCheckboxes from "@/components/common/Forms/SwitchesCheckboxes.vue";

export default {
  name: "PlTypeStep",
  components: { SwitchesCheckboxes },
  props: {
    plType: {
      type: Object,
      required: true,
    },
  },
  computed: {
    canProceed() {
      // Check if at least one PL type is selected
      return Object.values(this.plType).some((value) => value === true);
    },
  },
  data() {
    return {
      plTypeOptions: [
        { name: "pl2", label: "2PL", value: false },
        { name: "pl3", label: "3PL", value: false },
        { name: "pl4", label: "4PL", value: false },
        { name: "pl5", label: "5PL", value: false },
      ],
    };
  },
  methods: {
    updatePlTypeData(updatedValue) {
      this.$emit("update:plType", updatedValue); // Sync data with parent
    },
    nextStep() {
      this.$emit("change-step", "location"); // Emit step change
    },
  },
};
</script>

<style scoped></style>
