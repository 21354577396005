import apiClient from "@/api"; // Changed from axios
import { getStoredUser } from "./state";

export default {
  async initializeStore({ commit }) {
    const storedUser = getStoredUser();
    if (!storedUser) return;

    try {
      await apiClient.get("/validate-session"); // Changed to apiClient
      commit("SET_USER", { user: storedUser });
    } catch (error) {
      console.error("Session validation failed:", error);
      commit("LOGOUT");
    }
  },

  async fetchCsrfToken({ commit }) {
    try {
      // Make a request to the backend to ensure the cookie is set
      await apiClient.get("/csrf-token");

      // Read the _csrf cookie from the browser
      const csrfToken = document.cookie
        .split("; ")
        .find((row) => row.startsWith("_csrf="))
        ?.split("=")[1];

      if (!csrfToken) throw new Error("CSRF token missing");

      // Commit the token to the Vuex store
      commit("SET_CSRF_TOKEN", csrfToken);
      return csrfToken;
    } catch (error) {
      console.error("Failed to fetch CSRF token:", error);
      throw new Error("Failed to fetch CSRF token. Please try again.");
    }
  },

  async fetchUserLogo({ commit }, logoPath) {
    try {
      const logoBlob = logoPath
        ? await apiClient
            .get(`/images/${logoPath}`, {
              // Changed to apiClient
              responseType: "blob",
            })
            .then((res) => res.data)
        : null;
      commit("SET_USER_LOGO_BLOB", logoBlob);
    } catch (error) {
      console.error("Error fetching user logo:", error);
      commit("SET_USER_LOGO_BLOB", null);
    }
  },

  async login({ state, commit, dispatch }, { email, password }) {
    const MAX_ATTEMPTS = 5;
    const LOCKOUT_DURATION = 300000;

    if (
      state.failedPasswordAttempts >= MAX_ATTEMPTS &&
      Date.now() - state.lastPasswordAttempt < LOCKOUT_DURATION
    ) {
      const remaining = Math.ceil(
        (LOCKOUT_DURATION - (Date.now() - state.lastPasswordAttempt)) / 60000,
      );
      throw new Error(`Too many attempts. Try again in ${remaining} minutes`);
    }

    try {
      const response = await apiClient.post("/users/login", {
        email,
        password,
      }); // Changed to apiClient
      commit("SET_USER", { user: response.data.user });
      await dispatch("fetchUserLogo", response.data.user.logo);
      commit("SET_PASSWORD_ATTEMPT", { attempts: 0, timestamp: null });

      if (response.data?.csrfToken) {
        commit("SET_CSRF_TOKEN", response.data.csrfToken);
      }
    } catch (error) {
      commit("SET_PASSWORD_ATTEMPT", {
        attempts: state.failedPasswordAttempts + 1,
        timestamp: Date.now(),
      });

      const message =
        error.response?.status === 401
          ? "Invalid email or password."
          : "An error occurred during login.";
      throw new Error(message);
    }
  },

  async logout({ commit }) {
    try {
      await apiClient.post("/users/logout"); // Changed to apiClient
    } catch (error) {
      console.error("Logout error:", error);
    }
    commit("LOGOUT");
  },

  async register({ commit, dispatch }, payload) {
    try {
      const response = await apiClient.post("/users/register", payload); // Changed to apiClient
      commit("SET_USER", { user: response.data.user });
      await dispatch("fetchUserLogo", response.data.user.logo);

      if (response.data?.csrfToken) {
        commit("SET_CSRF_TOKEN", response.data.csrfToken);
      }
    } catch (error) {
      const message =
        error.response?.status === 409
          ? "Email already in use."
          : "Registration failed. Please try again.";
      throw new Error(message);
    }
  },

  async fetchUser({ commit, dispatch }, userId) {
    try {
      const response = await apiClient.get(`/users/${userId}`); // Changed to apiClient
      if (response?.data) {
        commit("SET_USER", { user: response.data });
        await dispatch("fetchUserLogo", response.data.logo);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  },
};
