<template>
  <CommonHeader />
  <div class="py-2.5 bg-background">
    <RegisterUser />
  </div>
  <CommonFooter />
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import RegisterUser from "@/components/SignIn/RegisterUser.vue";
import CommonFooter from "@/components/common/Footer.vue";

export default {
  metaInfo: {
    title: "User Registry | Biglo",
  },
  name: "UserRegister",
  components: {
    CommonHeader,
    RegisterUser,
    CommonFooter,
  },
};
</script>

<style scoped></style>
