<template>
  <div class="p-6 max-w-screen-xl mx-auto">
    <div class="bg-white shadow-lg rounded-lg overflow-hidden">
      <!-- Header Section -->
      <div class="relative">
        <img
          v-if="service?.imageUrl"
          :src="service.imageUrl"
          alt="Service Image"
          class="object-contain mx-auto"
        />
        <div
          v-else
          class="h-full flex items-center justify-center text-grey text-lg"
          alt="No Image Available"
        >
          No Image Available
        </div>
      </div>

      <!-- Service Details Section -->
      <div class="p-6">
        <!-- User Name -->
        <h1 class="text-brand-darkblue font-bold text-2xl mb-4">
          {{ service?.userName }}
        </h1>

        <!-- Description -->
        <div class="mb-6">
          <h2 class="text-brand-darkblue font-semibold text-lg mb-2">
            Description
          </h2>
          <p class="text-grey text-sm leading-6">
            {{ service?.description }}
          </p>
        </div>

        <!-- Address -->
        <div class="mb-6">
          <h2 class="text-brand-darkblue font-semibold text-lg mb-2">
            Address
          </h2>
          <p class="text-grey text-sm">
            {{ service?.location?.address }},
            {{ service?.location?.zip }}
          </p>
          <p class="text-grey text-sm">
            {{ service?.location?.city }}, {{ service?.location?.region }},
            {{ service?.location?.country }}
          </p>
        </div>

        <!-- Logistics Details -->
        <div class="mb-6">
          <h2 class="text-brand-darkblue font-semibold text-lg mb-2">
            Logistics Information
          </h2>
          <div class="grid grid-cols-2 gap-4">
            <div>
              <h3 class="font-bold text-sm">PL Types</h3>
              <ul class="space-y-1">
                <li
                  v-for="(value, key) in service?.plType"
                  :key="key"
                  class="bg-brand-darkblue text-white text-xs font-semibold rounded-full px-3 py-1 inline-block mr-1"
                >
                  {{ key }}: {{ value ? "Yes" : "No" }}
                </li>
              </ul>
            </div>
            <div>
              <h3 class="font-bold text-sm">International Coverage</h3>
              <p class="text-grey text-sm">
                Europe:
                {{
                  service?.location?.internationalCoverage?.europe
                    ? "Yes"
                    : "No"
                }}, Asia:
                {{
                  service?.location?.internationalCoverage?.asia ? "Yes" : "No"
                }}, N. America:
                {{
                  service?.location?.internationalCoverage?.northAmerica
                    ? "Yes"
                    : "No"
                }}, S. America:
                {{
                  service?.location?.internationalCoverage?.southAmerica
                    ? "Yes"
                    : "No"
                }}, Africa:
                {{
                  service?.location?.internationalCoverage?.africa
                    ? "Yes"
                    : "No"
                }}
              </p>
            </div>
          </div>
        </div>

        <!-- Dimensions -->
        <div class="mb-6">
          <h2 class="text-brand-darkblue font-semibold text-lg mb-2">
            Dimensions
          </h2>
          <ul class="space-y-1 text-grey text-sm">
            <li>
              <span class="font-semibold">Surface:</span>
              {{ service?.dimensions?.surface }} m²
            </li>
            <li>
              <span class="font-semibold">Height:</span>
              {{ service?.dimensions?.height }} m
            </li>
            <li>
              <span class="font-semibold">Pallet Positions:</span>
              {{ service?.dimensions?.palletPositions }}
            </li>
          </ul>
        </div>

        <!-- Transportation -->
        <div class="mb-6">
          <h2 class="text-brand-darkblue font-semibold text-lg mb-2">
            Transportation
          </h2>
          <ul class="space-y-1 text-grey text-sm">
            <li>
              Aerial:
              {{ service?.transportation?.aerialTransportation ? "Yes" : "No" }}
            </li>
            <li>
              Ground:
              {{ service?.transportation?.groundTransportation ? "Yes" : "No" }}
            </li>
            <li>
              Maritime:
              {{
                service?.transportation?.maritimeTransportation ? "Yes" : "No"
              }}
            </li>
          </ul>
        </div>

        <!-- Storage -->
        <div class="mb-6">
          <h2 class="text-brand-darkblue font-semibold text-lg mb-2">
            Storage
          </h2>
          <ul class="space-y-1 text-grey text-sm">
            <li>Dry: {{ service?.storageAndInventory?.dry ? "Yes" : "No" }}</li>
            <li>
              Refrigerated:
              {{ service?.storageAndInventory?.refrigerated ? "Yes" : "No" }}
            </li>
            <li>
              Freezer:
              {{ service?.storageAndInventory?.freezer ? "Yes" : "No" }}
            </li>
          </ul>
        </div>

        <!-- Delete Button -->
        <div class="mt-6 flex justify-center">
          <a
            target="_blank"
            :href="emailHref"
            class="bg-brand-darkblue border border-brand-darkblue hover:bg-brand-yellow hover:text-brand-darkblue text-brand-yellow font-semibold py-2 px-4 rounded shadow-lg"
          >
            Contact owner
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      service: null,
      serviceId: this.$route.params.serviceId,
      fallbackImage: require("@/assets/images/service-icon.png"),
    };
  },
  computed: {
    emailHref() {
      const email = "biglospain@gmail.com";
      const subject = `Interested in ${this.service?.userName} (id: ${this.serviceId})`;
      return `mailto:${email}?subject=${encodeURIComponent(subject)}`;
    },
  },
  created() {
    this.fetchServiceDetails();
  },
  methods: {
    async fetchServiceDetails() {
      try {
        const response = await axios.get(
          `/services/${this.$route.params.serviceId}`,
        );
        const service = response.data;

        service.imageUrl = await this.getServiceImage(service);
        this.service = service;
      } catch (error) {
        console.error("Error fetching service details:", error.message);
      }
    },

    async getServiceImage(service) {
      try {
        const imageId = service?.multimedia?.images?.[0];
        if (!imageId) return this.fallbackImage;

        const response = await axios.get(`/images/${imageId}`, {
          responseType: "blob",
        });
        return response.status >= 200 && response.status < 300
          ? URL.createObjectURL(response.data)
          : this.fallbackImage;
      } catch (error) {
        console.error("Error fetching service image:", error.message);
        return this.fallbackImage;
      }
    },
  },
};
</script>

<style scoped></style>
