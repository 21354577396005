<template>
  <div class="relative mx-auto operations-step">
    <span class="block text-2xl font-semibold text-brand-darkblue text-center">
      <i class="fa-solid fa-screwdriver-wrench"></i>
      2nd step: Operations
    </span>
    <span
      class="block mt-2 text-base font-normal text-brand-darkblue text-center"
    >
      (Select as many operations as you can perform or are willing to)
    </span>

    <!-- Switches/Checkboxes component -->
    <SwitchesCheckboxes
      :options="operationsOptions"
      :model-value="localOperationsData"
      @update:modelValue="updateOperationsData"
    />
  </div>
</template>

<script>
import SwitchesCheckboxes from "@/components/common/Forms/SwitchesCheckboxes.vue";

export default {
  name: "OperationsStep",
  components: { SwitchesCheckboxes },
  props: {
    operationsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localOperationsData: { ...this.operationsData },
      operationsOptions: [
        { name: "eCommerce", label: "E-commerce", value: false },
        { name: "crossDocking", label: "Cross-Docking", value: false },
        { name: "temporary", label: "Temporary Storage", value: false },
        { name: "distribution", label: "Distribution", value: false },
        { name: "consolidation", label: "Consolidation", value: false },
      ],
    };
  },
  watch: {
    // Watch for changes in the prop and update the local copy
    operationsData: {
      deep: true,
      handler(newVal) {
        this.localOperationsData = { ...newVal };
      },
    },
  },
  methods: {
    updateOperationsData(updatedData) {
      this.localOperationsData = updatedData;
      // Emit the update to parent component
      this.$emit("update:operationsData", updatedData);
    },
  },
};
</script>
