<template>
  <div
    class="relative transition-all duration-200 ease-in-out flex flex-row items-center"
  >
    <!-- Button to toggle dropdown -->
    <button
      id="toggle-dropdown"
      type="button"
      class="py-3 pl-4 pr-1 shrink-0 border rounded-l-lg flex items-center justify-center relative bg-background border-primary hover:border-status-info hover:text-status-info transition-colors duration-200 ease-in-out"
      :class="{
        '!border-status-success': selectedCountry,
        'bg-primary': isDropdownOpen,
      }"
      @click="toggleDropdown"
      @keydown="handleKeydown"
      :aria-expanded="isDropdownOpen"
      aria-controls="dropdown-content"
      :disabled="isDetecting"
    >
      <span v-if="isDetecting" class="pr-[2px]">⏳</span>
      <template v-else>
        <img
          v-if="selectedCountry"
          :src="`https://flagcdn.com/h20/${selectedCountry.code.toLowerCase()}.jpg`"
          :alt="selectedCountry.name"
          class="w-6 h-6 py-1"
        />
        <span v-else>🌍</span>
      </template>

      <!-- Dropdown arrow -->
      <div
        class="transition-transform duration-200 ease-in-out ml-2 hover:text-status-info"
        :class="{
          'rotate-180 text-status-info': isDropdownOpen,
        }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </button>

    <!-- Dial code -->
    <span
      v-if="!selectedCountry"
      class="px-2 py-6 self-center border-y border-primary w-[5.23125rem] text-center truncate bg-background"
    ></span>
    <span
      v-if="selectedCountry"
      class="px-2 py-3 self-center border-y border-primary w-[5.23125rem] text-center truncate bg-background transition-colors duration-200 ease-in-out"
      :class="{
        '!border-status-success': selectedCountry,
        'bg-primary': isDropdownOpen,
        'text-background': isDropdownOpen,
      }"
    >
      {{ selectedCountry.dialCode }}
    </span>

    <!-- Dropdown with flags grouped by continent -->
    <div
      v-if="isDropdownOpen"
      id="dropdown-content"
      ref="dropdownContent"
      class="absolute z-10 w-full bg-white border-t-none border border-status-info rounded-lg rounded-tr-none shadow-lg max-h-64 overflow-y-auto mt-px overscroll-contain"
      style="top: 100%; left: 0"
      @keydown="handleDropdownKeydown"
      tabindex="0"
    >
      <!-- Search input (sticky at the top) -->
      <div class="sticky top-0 bg-white z-20">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Search country"
          class="w-full px-4 py-2 border-b border-brand-darkblue text-brand-darkblue focus:outline-none focus:border-status-info"
          @input="handleSearch"
        />
      </div>

      <!-- Loop through each continent -->
      <div
        v-for="(countries, continent) in filteredGroupedCountries"
        :key="continent"
      >
        <!-- Continent header -->
        <div class="px-2 py-2 font-semibold text-brand-darkblue">
          {{ continent }}
        </div>
        <!-- Loop through countries in the continent -->
        <div
          v-for="country in countries"
          :key="country.code"
          :id="`country-${country.code}`"
          :class="{ 'bg-status-info text-white': isCountrySelected(country) }"
          class="flex items-center px-4 py-2 cursor-pointer text-background"
          @click="selectCountry(country)"
          @mousedown.prevent
        >
          <img
            :src="`https://flagcdn.com/${country.code.toLowerCase()}.svg`"
            :alt="country.code"
            loading="lazy"
            class="w-6 mr-2"
          />
          <span class="p-0">{{ country.dialCode }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countries from "@/assets/location/countries/countriesDialCode.json";

export default {
  name: "TelephoneCountryPrefixCustom",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      isDetecting: false,
      isDropdownOpen: false,
      selectedCountry: null,
      groupedCountries: countries,
      selectedCountryIndex: -1,
      searchQuery: "",
    };
  },
  computed: {
    flattenedCountries() {
      return Object.values(this.groupedCountries).flat();
    },
    filteredGroupedCountries() {
      const query = this.searchQuery.toLowerCase();
      if (!query) return this.groupedCountries;

      const filtered = {};
      for (const [continent, countries] of Object.entries(
        this.groupedCountries,
      )) {
        const filteredCountries = countries.filter((country) => {
          return (
            country.name.toLowerCase().includes(query) ||
            country.code.toLowerCase().includes(query) ||
            country.dialCode.includes(query) ||
            (country.altNames &&
              country.altNames.some((alt) => alt.toLowerCase().includes(query)))
          );
        });

        if (filteredCountries.length > 0) {
          filtered[continent] = filteredCountries;
        }
      }
      return filtered;
    },
    // Get all visible (filtered) countries as a flat array
    visibleCountries() {
      return Object.values(this.filteredGroupedCountries).flat();
    },
  },
  methods: {
    handleSearch() {
      this.selectedCountryIndex = 0; // Reset selection when searching
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      if (this.isDropdownOpen) {
        this.$nextTick(() => {
          this.selectedCountryIndex = this.selectedCountry
            ? this.visibleCountries.findIndex(
                (c) => c.code === this.selectedCountry.code,
              )
            : 0;

          if (this.selectedCountryIndex === -1) this.selectedCountryIndex = 0;
          this.$refs.dropdownContent.focus();
          this.scrollToSelectedCountry();
        });
      }
    },
    scrollToSelectedCountry() {
      this.$nextTick(() => {
        if (this.selectedCountryIndex >= 0) {
          const container = this.$refs.dropdownContent;
          const selectedCountry =
            this.visibleCountries[this.selectedCountryIndex];
          const element = document.getElementById(
            `country-${selectedCountry.code}`,
          );

          if (element && container) {
            const elementTop = element.offsetTop - container.offsetTop;
            const elementHeight = element.offsetHeight;
            const containerHeight = container.clientHeight;
            container.scrollTop =
              elementTop - containerHeight / 2 + elementHeight / 2;
          }
        }
      });
    },
    isCountrySelected(country) {
      return (
        this.selectedCountryIndex >= 0 &&
        this.visibleCountries[this.selectedCountryIndex]?.code === country.code
      );
    },
    selectCountry(country) {
      const prefix = country.dialCode.startsWith("+")
        ? country.dialCode
        : `+${country.dialCode}`;
      this.selectedCountry = country;
      this.$emit("update:modelValue", prefix);
      this.isDropdownOpen = false;
      this.selectedCountryIndex = this.visibleCountries.findIndex(
        (c) => c.code === country.code,
      );
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isDropdownOpen = false;
      }
    },
    handleKeydown(event) {
      if (event.key === "Escape") {
        this.isDropdownOpen = false;
      } else if (event.key === "ArrowDown" && !this.isDropdownOpen) {
        event.preventDefault();
        this.toggleDropdown();
      }
    },
    handleDropdownKeydown(event) {
      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          this.updateSelection(
            (this.selectedCountryIndex + 1) % this.visibleCountries.length,
          );
          break;

        case "ArrowUp":
          event.preventDefault();
          this.updateSelection(
            (this.selectedCountryIndex - 1 + this.visibleCountries.length) %
              this.visibleCountries.length,
          );
          break;

        case "Enter":
        case " ":
          event.preventDefault();
          if (this.selectedCountryIndex >= 0) {
            this.selectCountry(
              this.visibleCountries[this.selectedCountryIndex],
            );
          }
          break;

        case "Escape":
          this.isDropdownOpen = false;
          break;
      }
    },
    updateSelection(newIndex) {
      this.selectedCountryIndex = newIndex;
      this.scrollToSelectedCountry();
    },
    async detectUserCountry() {
      this.isDetecting = true;
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        const countryCode = data.country_code;
        const country = this.flattenedCountries.find(
          (c) => c.code === countryCode,
        );

        if (country) {
          this.selectedCountry = country;
          this.selectedCountryIndex = this.flattenedCountries.findIndex(
            (c) => c.code === countryCode,
          );
          this.$emit("update:modelValue", country.dialCode);
        }
      } catch (error) {
        console.error("Country detection failed, using US default:", error);
        const defaultCountry = this.flattenedCountries.find(
          (c) => c.code === "US",
        );
        this.selectedCountry = defaultCountry;
        this.selectedCountryIndex = this.flattenedCountries.findIndex(
          (c) => c.code === "US",
        );
        this.$emit("update:modelValue", defaultCountry.dialCode);
      } finally {
        this.isDetecting = false;
      }
    },
  },
  async mounted() {
    await this.detectUserCountry();
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
/* Existing styles remain unchanged */
img,
span {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.rotate-180 {
  transform: rotate(180deg);
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.overscroll-contain {
  overscroll-behavior: contain;
}
</style>
