<template>
  <div class="flex flex-row max-w-fit ml-auto my-1.5">
    <p class="ml-auto mr-1 my-auto text-brand-darkblue font-medium text-xl">
      Sort By:
    </p>
    <div class="flex flex-row">
      <input
        type="radio"
        name="sort-option"
        id="distance"
        class="hidden"
        v-model="sortBy"
        value="distance"
      />
      <label
        for="distance"
        class="text-white cursor-pointer py-0aureo px-4 rounded-[5px] bg-brand-darkblue border border-white font-normal mx-1 hover:border-brand-yellow hover:text-brand-yellow focus:border-brand-yellow focus:text-brand-yellow"
        >Distance</label
      >
      <input
        type="radio"
        name="sort-option"
        id="price"
        class="hidden"
        v-model="sortBy"
        value="price"
      />
      <label
        for="price"
        class="text-white cursor-pointer py-0aureo px-4 rounded-[5px] bg-brand-darkblue border border-white font-normal mx-1 hover:border-brand-yellow hover:text-brand-yellow focus:border-brand-yellow focus:text-brand-yellow"
        >Price</label
      >
      <input
        type="radio"
        name="sort-option"
        id="spaceAvailable"
        class="hidden"
        v-model="sortBy"
        value="spaceAvailable"
      />
      <label
        for="spaceAvailable"
        class="text-white cursor-pointer py-0aureo px-4 rounded-[5px] bg-brand-darkblue border border-white font-normal ml-1 hover:border-brand-yellow hover:text-brand-yellow focus:border-brand-yellow focus:text-brand-yellow"
        >Space Available</label
      >
    </div>
  </div>
  <div class="flex flex-col mt-2">
    <ResultsItem
      v-for="resultsItem in resultsItems"
      :key="resultsItem.id"
      :resultsItem="resultsItem"
    />
  </div>
</template>
<script>
import ResultsItem from "./ResultsItem.vue";

export default {
  name: "ResultsList",
  props: ["resultsItems"],
  components: {
    ResultsItem,
  },
  data() {
    return {
      sortBy: "distance",
    };
  },
  methods: {
    sortResults() {
      this.$emit("sort", this.sortBy);
    },
  },
  watch: {
    sortBy() {
      this.sortResults();
    },
  },
};
</script>
